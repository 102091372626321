import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Text } from '@system'

const useStyles = makeStyles((theme) => {
  return {
    link: {
      textAlign: 'center',
      display: 'block',
      fontWeight: theme.typography.fontWeightRegular,
      '& a': {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        '&:hover': {
          borderColor: theme.palette.secondary.main,
        },
      },
    },
  }
})

const StatWithSubtitleAndLink = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      {renderBloks(props.blok.statWithSubtitle)}
      <Text className={classes.link}>
        {!!props.blok.link && renderBloks(props.blok.link)}
      </Text>
    </SbEditable>
  )
}

export default StatWithSubtitleAndLink
